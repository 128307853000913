import React, { Component } from "react";

export default class PageNotFound extends Component {
  render() {
    return (
      <div class="page-not-found Content">
        <main class="container">
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">4</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
          <span class="particle">0</span>
        </main>
      </div>
    );
  }
}
